<template>
    <div class="map-item-filter">
        <div
            class="col-sm-12 nopads vehicle-list"
        >
            <span
                class="vehicle-list-title pb-2"
                @click.stop="toggleList"
            >
                {{ $t('map_filters.contract_filter') }}
            </span>
            <div
                class="title col-12 text-center pb-2 pt-0"
                style="color: #BEFF41"
                v-if="selectedContracts && selectedContracts.length > 0
                    && !listOpen"
            >
                {{ selectedContracts.length === 1 ? selectedContracts[0].name : ((selectedContracts.length) + ' ' + $t('map_filters.contracts_selected'))}}
            </div>
            <div
                class="vehicle-list-caret"
                v-bind:class="{ caret_open: listOpen }"
                @click.stop="toggleList"
            >
                <font-awesome-icon icon="caret-down"/>
            </div>

            <div
                class="col-sm-12 nopads map-accordion-content"
                v-bind:class="{ 'map-accordion-open': listOpen }"
            >
                <div v-bind:class="{ 'm-2': listOpen }">
                    <div class="badge-filters__wrapper">
                        <div class="badge-filters">
                            <b-badge
                                v-for="item in timeFilters"
                                :key="item.value"
                                @click="selectTimeFilter(item.value)"
                                :variant="isActiveTimeFilter(item.value) ? 'primary' : 'light'"
                                style="padding: .5em 1em"
                            >
                                {{ item.text }}
                            </b-badge>
                        </div>
                    </div>
                    <b-row class="pt-0 mt-0">
                        <b-form-checkbox
                            v-model="onlyUserOwnedContracts"
                            size="sm"
                            type="text"
                            class="mt-1 text-light"
                            value=1
                            unchecked-value=0>
                            {{ $t('contracts.only_user_owned_contracts') }}
                        </b-form-checkbox>
                    </b-row>
                    <b-row  class="hidden" v-bind:class="{ 'multiselect-expand': multiselectContractCollectionOpen }">
                        <multiselect
                            v-model="selectedContractCollectionValue"
                            :options="contractCollectionOptions"
                            :multiple="false"
                            :close-on-select="true"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :internal-search="true"
                            :selected-label="$t('common.selected')"
                            :select-label="$t('common.select')"
                            :deselect-label="$t('common.remove_selection')"
                            :placeholder="$t('orders.select_contract_collection')"
                            :disabled="!this.contractCollectionOptions || this.contractCollectionOptions.length < 1 || isLoadingContracts"
                            :max-height="300"
                            @open="multiselectContractCollectionOpen = true"
                            @close="multiselectContractCollectionOpen = false"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="props"
                            >
                                <span class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                                </span>
                            </template>
                            <template
                                slot="option"
                                slot-scope="props"
                            >
                                <div class="option__desc">
                                    <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                                </div>
                            </template>
                            <span slot="noResult">{{ $t('common.not_found') }}</span>
                            <span slot="noOptions">{{ $t('common.no_results') }}</span>
                        </multiselect>
                    </b-row>
                    <b-row v-bind:class="{ 'multiselect-expand': multiselectContractOpen }">
                        <multiselect
                            v-model="multiselectValue"
                            :options="contracts"
                            :multiple="true"
                            :close-on-select="true"
                            label="name"
                            :custom-label="contractName"
                            track-by="name"
                            :searchable="true"
                            :internal-search="true"
                            :selected-label="$t('common.selected')"
                            :select-label="$t('common.select')"
                            :deselect-label="$t('common.remove_selection')"
                            :placeholder="$t('orders.select_contract')"
                            :disabled="!this.contracts || this.contracts.length < 1 || isLoadingContracts"
                            :max-height="300"
                            @open="multiselectContractOpen = true"
                            @close="multiselectContractOpen = false"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="props"
                            >
                                <span class="option__desc">
                                <span class="option__title">{{ props.option.name + (props.option.contract_number ? ' - ' + props.option.contract_number : '') }}</span>
                                </span>
                            </template>
                            <template
                                slot="option"
                                slot-scope="props"
                            >
                                <div class="option__desc">
                                    <span class="option__title multiselect_detail_block">{{ props.option.name + (props.option.contract_number ? ' - ' + props.option.contract_number : '') }}</span>
                                </div>
                            </template>
                            <span slot="noResult">{{ $t('common.not_found') }}</span>
                            <span slot="noOptions">{{ $t('common.no_results') }}</span>
                        </multiselect>
                    </b-row>
                    <b-row v-bind:class="{ 'multiselect-expand': multiselectTaskTypeOpen }">
                        <multiselect
                            v-model="selectedTaskTypeValues"
                            class="map-vehicle-taskType-multiselect"
                            :placeholder="$t('contracts.select_task_type')"
                            :deselect-label="$t('common.remove_selection')"
                            :select-label="$t('common.select')"
                            :close-on-select="false"
                            :multiple="true"
                            :open-direction="'below'"
                            label="text"
                            track-by="text"
                            :searchable="true"
                            :internal-search="true"
                            :selected-label="$t('common.selected')"
                            :options="taskTypeOptions"
                            :disabled="!multiselectValue || multiselectValue.length < 1"
                            :max-height="300"
                            @open="multiselectTaskTypeOpen = true"
                            @close="multiselectTaskTypeOpen = false"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="props"
                            >
                                <b-row class="nopads option__desc">
                                    <b-col cols="auto" class="nopads">
                                        <div
                                            v-if="props.option.value"
                                            class="task-type-circle"
                                            v-bind:style="{ backgroundColor: getTraceColor(logModes.WORK, props.option.color) }"
                                        />
                                    </b-col>
                                    <b-col>
                                        <span class="option__title">{{props.option.text }}</span>
                                    </b-col>
                                </b-row>
                            </template>
                            <template slot="option"
                                        slot-scope="props">
                                <b-row class="nopads">
                                    <b-col cols="auto" class="nopads">
                                        <div
                                            v-if="props.option.value"
                                            class="task-type-circle"
                                            v-bind:style="{ backgroundColor: getTraceColor(logModes.WORK, props.option.color) }"
                                        />
                                    </b-col>
                                    <b-col>
                                        <span class="option__title multiselect_detail_block">{{ props.option.text }}</span>
                                    </b-col>
                                </b-row>
                            </template>

                            <span slot="noResult">{{ $t('common.not_found') }}</span>
                            <span slot="noOptions">{{ $t('common.no_results') }}</span>
                        </multiselect>
                    </b-row>
                    <b-row v-bind:class="{ 'multiselect-expand': multiselectOrderOpen }">
                        <multiselect
                            v-model="selectedOrderValue"
                            class="map-order-multiselect"
                            :placeholder="$t('contracts.select_order')"
                            :deselect-label="$t('common.remove_selection')"
                            :select-label="$t('common.select')"
                            :close-on-select="true"
                            :multiple="false"
                            :open-direction="'below'"
                            label="text"
                            track-by="id"
                            :searchable="true"
                            :internal-search="true"
                            :selected-label="$t('common.selected')"
                            :options="orderOptions"
                            :disabled="!orderOptions || orderOptions.length < 1"
                            :max-height="300"
                            @open="multiselectOrderOpen = true"
                            @close="multiselectOrderOpen = false"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="props"
                            >
                                <b-row class="nopads option__desc">
                                    <b-col cols="auto" class="nopads">
                                        <div
                                            v-if="props.option.id"
                                            class="task-type-circle"
                                            v-bind:style="{ backgroundColor: props.option.contract_task_type.task_type.color }"
                                        />
                                    </b-col>
                                    <b-col>
                                        <span class="option__title">{{ getOrderStringWithInfo(props.option) }}</span>
                                    </b-col>
                                </b-row>
                            </template>
                            <template slot="option"
                                        slot-scope="props">
                                <b-row class="nopads">
                                    <b-col cols="auto" class="nopads">
                                        <div
                                            v-if="props.option.id"
                                            class="task-type-circle"
                                            v-bind:style="{ backgroundColor: props.option.contract_task_type.task_type.color }"
                                        />
                                    </b-col>
                                    <b-col>
                                        <span class="option__title multiselect_detail_block" :class="{ closed : !isOrderOpen(props.option)}">
                                            <span class="option__title">{{ getOrderStringWithInfo(props.option) }}</span>
                                        </span>
                                    </b-col>
                                </b-row>
                            </template>

                            <span slot="noResult">{{ $t('common.not_found') }}</span>
                            <span slot="noOptions">{{ $t('common.no_results') }}</span>
                        </multiselect>
                    </b-row>
                    <b-row>
                        <div class="badge-filters__wrapper">
                            <div class="badge-filters pt-1">
                                <b-badge
                                    v-show="selectedContractIds || selectedContractIds.length > 0"
                                    @click="$refs.geomOrderSelector.show()"
                                    :variant="showContractGeometry ? 'primary' : 'light'"
                                    style="padding: .5em 1em"
                                >
                                    {{ $t('orders.show_geometry') }}
                                </b-badge>
                                <b-badge
                                    v-show="selectedContractIds || selectedContractIds.length > 0"
                                    @click="hideOrderGeometry"
                                    :variant="showContractGeometry ? 'light' : 'primary'"
                                    style="padding: .5em 1em"
                                >
                                    {{ $t('map.hide_geometry') }}
                                </b-badge>
                            </div>
                        </div>
                    </b-row>
                    <b-row>
                        <b-form-checkbox
                            v-if="showContractGeometry"
                            id="set-contract-order-geometries-labels"
                            @change="setShowContractGeometryLabels"
                            switch
                            size="sm"
                            class="mt-1 text-light"
                            type="text"
                            :disabled="!selectedContractIds || selectedContractIds.length === 0"
                            :checked="showContractGeometryLabels"
                        >
                            {{ $t('areas.show_labels') }}
                        </b-form-checkbox>
                    </b-row>
                    <b-row>
                        <b-form-checkbox
                            id="set-traffic-control"
                            @change="setShowTrafficControl"
                            switch
                            size="sm"
                            class="mt-1 text-light"
                            type="text"
                            :checked="showTrafficControl"
                        >
                            {{ $t('map_filters.select_traffic_control') }}
                        </b-form-checkbox>
                    </b-row>
                    <div v-if="contractsError" class="text-danger">{{ $t('common.error') }}</div>
                    <b-row class="mb-0 pb-0">
                        <div class="col-12 nopads text-right">
                            <b-badge
                                @click="clearFilters"
                                variant="outline-light"
                                class="btn btn-outline-light mb-3"
                                pill
                                style="padding: .5em 1em; cursor: pointer;"
                            >
                                {{ $t('common.clear_filters') }}
                            </b-badge>
                        </div>
                    </b-row>
                </div>
            </div>
        </div>
        <b-modal
            ref="geomOrderSelector"
            centered
            size="lg"
            hide-footer
            :title="$t('orders.show_geometry')">
            <div class="col-12 pb-2">
                <span>{{ $t('map.show_geometry_hint')}}</span>
            </div>
            <div  class="col-12 pb-4">
                <span style="color: #9e0c0c">{{ $t('map.show_geometry_warning')}}</span>
            </div>
            <!-- Order selections by contract -->
            <div class="col-12">
                <b-form-checkbox-group
                    class="mt-2"
                    v-model="selectedGeometryOrderValues"
                    :options="parsedOrderOptions"
                    stacked
                />
            </div>
            <div class="col-12 text-right">
                <b-button
                    variant="secondary"
                    class="form-button"
                    @click.stop="$refs.geomOrderSelector.hide()"
                >
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button
                    variant="success"
                    class="form-button"
                    :disabled="!selectedGeometryOrderValues || selectedGeometryOrderValues.length < 1"
                    @click.stop="saveSelectedGeometryOrders"
                >
                    {{ $t('common.search') }}
                </b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'
import {timeUtils} from '../mixins/TimeUtils'
import {logModes, vehicleHelper} from "../mixins/VehicleMixin";
import {mapHelper} from "@/components/mixins/MapMixin";
import {orderMixin} from "@/components/mixins/OrderMixin";
import {restApi} from "@/components/mixins/RestApiMixin";

const STATUS = {
    ALL: 0,
    UPCOMING: 1,
    INPROGRESS: 2,
    READY: 3,
}
const INIT_STATUS = STATUS.INPROGRESS

export default {
    name: "map-contract-filter",
    mixins: [timeUtils, vehicleHelper, orderMixin, restApi, mapHelper],
    data() {
        return {
            listOpen: false,
            loading: false,
            selectedTimeFilter: INIT_STATUS,
            multiselectContractCollectionOpen: false,
            multiselectContractOpen: false,
            multiselectTaskTypeOpen: false,
            multiselectOrderOpen: false,
            contracts: [],
            contractCollectionOptions: [],
            logModes: logModes,
            multiselectValue: null,
            selectedTaskTypeValues: [],
            selectedGeometryOrderValues: [],
            selectedOrderValue: null,
            selectedContractCollectionValue: null,
            onlyUserOwnedContracts: 0,
            allOrders: [],
            allContracts: [],
            orderOptions: [],
        };
    },
    computed: {
        ...mapState({
            isLoadingContracts: (state) => state.contracts.isLoading,
            selectedContractIds: (state) => state.contracts.selectedContractIds,
            selectedContracts: (state) => state.contracts.selectedContracts,
            showContractGeometry: (state) => state.contracts.showContractGeometry,
            showContractGeometryLabels: (state) => state.contracts.showContractGeometryLabels,
            selectedGeometryOrders: (state) => state.contracts.selectedGeometryOrders,
            showTrafficControl: (state) => state.contracts.showTrafficControl,
            selectedTaskType: (state) => state.contracts.selectedTaskType,
            contractsError: (state) => state.contracts.error
        }),
        taskTypeOptions() {
            const nullVal = { value: null, text: this.$t('contracts.select_task_type') };
            if (!this.selectedContracts || this.selectedContracts.length === 0 || this.isLoadingContracts) {
                return [nullVal];
            }
            const taskTypes = [];
            for (const contract of this.selectedContracts) {
                for (const item of contract.task_types) {
                    taskTypes.push({
                        value: item.task_type.id,
                        text: item.task_type.name,
                        color: item.task_type.color
                    });
                }
            }
            // TODO - Order task types in alphabetical order!
            return [nullVal, ...taskTypes];
        },
        amountOfFiltersSelected() {
            return (this.selectedTimeFilter ? 1 : 0) + (this.selectedContractIds ? 1 : 0)
        },
        contractOptions() {
            const nullVal = { value: null, text: this.isLoadingContracts ? this.$t('common.loading') : this.$t('map_filters.select_contract') }
            if ((!this.contracts || !this.contracts.length) || this.isLoadingContracts) {
                return [nullVal]
            }
            return [
                nullVal,
                ...this.contracts.map(item => ({
                    value: item,
                    text: item.name + " - " + (item.customer.name)
                }))
            ]
        },
        timeFilters() {
            return [
                {
                    text: this.$t("common.all"),
                    value: STATUS.ALL,
                },
                {
                    text: this.$t("common.upcoming"),
                    value: STATUS.UPCOMING,
                },
                {
                    text: this.$t("common.in_progress"),
                    value: STATUS.INPROGRESS,
                },
                {
                    text: this.$t("map_filters.contracts_ready"),
                    value: STATUS.READY,
                },
            ]
        },
        parsedOrderOptions() {
            let options = []
            this.orderOptions.forEach(o => options.push({text: this.getOrderStringWithInfo(o), value: o.id}))
            return options
        }
    },
    watch: {
        selectedContractIds(val) {
            if (this.contracts && this.contracts.length > 1 &&  (!val || val.length < 1)) {
                this.hideOrderGeometry()
            }
        },
        multiselectValue(val) {
            let contracts = val && val.length > 0 ? val : []
            this.selectContracts(contracts);
            this.updateMapSettings({ buttons: { selectedContracts: this.getSimpleContracts(contracts) }});
            this.saveMapSettings();
            this.selectedOrderValue = null
            this.selectedTaskTypeValues = []
            this.fetchOrders()
        },
        selectedTimeFilter() {
            this.setContracts()
        },
        onlyUserOwnedContracts() {
            this.setContracts()
        },
        selectedTaskTypeValues() {
            this.setTaskTypes(this.selectedTaskTypeValues)
            // Other existing logic
            if (this.selectedTaskTypeValues && this.selectedTaskTypeValues.length > 0 && this.selectedOrderValue) {
                const selectedTaskTypeIds = this.selectedTaskTypeValues.map(taskType => taskType.value);

                if (!selectedTaskTypeIds.includes(this.selectedOrderValue.contract_task_type.task_type.id)) {
                    this.selectedOrderValue = null;
                }
            }
            this.filterOrders();
        },
        selectedOrderValue(order) {
            this.selectOrder(order ? order.id : null)
        },
        selectedContractCollectionValue(val) {
            let filteredContracts = []
            this.allContracts.forEach(contract => {
                if(contract && contract.contract_collection && contract.contract_collection.id && val && val.id && val.id === contract.contract_collection.id) {
                    filteredContracts.push(contract)
                }
            })
            this.contracts = filteredContracts
            this.selectedOrderValue = null
            this.selectedTaskTypeValues = []
            this.multiselectValue = null
        },
        contracts() {
           this.initContractSelection()
        }
    },
    mounted() {
        this.initContractSelection()
    },
    methods: {
        ...mapActions('contracts', ['getContracts', 'selectContract', 'setShowContractGeometry', 'setShowContractGeometryLabels', 'setShowTrafficControl',
            'selectTaskType', 'setTaskTypes', 'selectOrder', 'selectContracts', 'selectGeometryOrders']),
        getSimpleContracts(contracts) {
            if (contracts) {
                let result = []
                contracts.forEach( contract => result.push({id: contract.id, name: contract.name}))
                return result
            }
            return null
        },

        contractName ({ name, contract_number }) {
            return contract_number ? `${name} - ${contract_number}` : `${name}`
        },
        getSimpleOrders(orders) {
            if (orders) {
                let result = []
                orders.forEach( order => result.push({id: order.id, name: order.contract_task_type.task_type.name}))
                return result
            }
            return null
        },
        saveSelectedGeometryOrders() {
            let orders = []
            this.selectedGeometryOrderValues.forEach(selectedOrder => orders.push(this.orderOptions.find(o => o.id === selectedOrder)))
            this.selectGeometryOrders(orders);
            this.setShowContractGeometry(orders.length > 0)
            this.updateMapSettings({ buttons: { selectedGeometryOrders: this.getSimpleOrders(orders) }});
            this.updateMapSettings({ buttons: { showContractGeometry: orders.length > 0}});
            this.saveMapSettings();
            this.$refs.geomOrderSelector.hide()
        },
        contractQueryParams(status = INIT_STATUS) {
            const {ALL, UPCOMING, INPROGRESS, READY} = STATUS
            let now = new Date().getTime()
            let params = {}
            if (parseInt(this.onlyUserOwnedContracts) === 1) {
                params.onlyUserOwnedContracts = 1
            }
            switch (status) {
                case ALL:
                    return params
                case UPCOMING:
                    params.startDate = now
                    return params
                case INPROGRESS:
                    params.open = 1
                    return params
                case READY:
                    params.past = 1
                    return params
                default:
                    return INPROGRESS
            }
        },
        async setContracts() {
            let results = await this.getContracts(this.contractQueryParams(this.selectedTimeFilter));
            let validatedContracts = []
            this.contractCollectionOptions = []
            if (this.contracts && this.contracts.length > 0 || (results && results.length > 0) ) {
                if (results) {
                    this.contracts = results
                    this.allContracts = results
                }
                // Create a helper set to track the ids of contract collections that have already been processed
                const addedCollectionIds = new Set();

                this.contracts.forEach(contract => {
                    if (contract && contract.contract_collection && contract.contract_collection.id && !addedCollectionIds.has(contract.contract_collection.id)) {
                        // Push the new collection into the options array
                        this.contractCollectionOptions.push({
                            id: contract.contract_collection.id,
                            name: contract.contract_collection.name
                        });
                        // Add the id to the set to prevent future duplicates
                        addedCollectionIds.add(contract.contract_collection.id);
                    }
                });
            }
            let preSelectedContracts = this.getMapSettingsIn(['buttons', 'selectedContracts'], false);
            if(preSelectedContracts && preSelectedContracts.length > 0) {
                preSelectedContracts.forEach(preSelectedContract => {
                    this.contracts.forEach(contract => {
                        if(contract.id === preSelectedContract.id) {
                            validatedContracts.push(contract)
                        }
                    })
                })
                this.selectContracts(validatedContracts)
            }
            this.initMultiselectValue();
        },
        initContractSelection() {
            if (!this.contractsError && (!this.contracts || this.contracts.length === 0)) {
                this.setContracts(INIT_STATUS);
            } else if (this.contracts && this.contracts.length > 0) {
                if (this.selectedContracts) {
                    this.multiselectValue = this.selectedContracts
                }
                this.initMultiselectValue()
            }
        },
        toggleList() {
            this.listOpen = !this.listOpen;
        },
        selectTimeFilter(val) {
            this.selectContracts(null)
            this.selectedTimeFilter = val
        },
        getTraceColor: function (mode, color) {
            if (mode === logModes.WORK) {
                return color
            }
            return '#bcbcbc'
        },
        isActiveTimeFilter(val) {
            return val === this.selectedTimeFilter
        },
        hideOrderGeometry() {
            this.setShowContractGeometry(false)
            this.selectGeometryOrders([])
            this.updateMapSettings({ buttons: { selectedGeometryOrders: [] }});
            this.updateMapSettings({ buttons: { showContractGeometry: false }});
            this.saveMapSettings();
            this.selectedGeometryOrderValues = []
        },
        clearFilters() {
            this.selectedTimeFilter = INIT_STATUS
            this.multiselectValue = null
            this.selectedTaskTypeValues = []
            this.selectedContractCollectionValue = null
            this.selectedOrderValue = null
            this.selectContracts(null)
            this.selectGeometryOrders([])
            this.selectOrder(null)
            this.setShowContractGeometry(false)
            this.setShowContractGeometryLabels(false)
            this.setShowTrafficControl(false)
        },
        initMultiselectValue() {
            this.multiselectValue = this.selectedContracts
        },
        fetchOrders() {
            this.orderOptions = []
            this.allOrders = []
            if (this.selectedContracts && this.selectedContracts.length > 0) {
                this.loading = true
                this.restFetchParams(this.orderUrl, {contract: this.selectedContracts.map(item => item.id)}, this.handleOrders)
            }
        },
        handleOrders: function (response) {
            if (response) {
                this.allOrders = response.data
                this.filterOrders()
                this.initGeometryOrders()
            }
            this.loading = false
        },
        filterOrders() {
            // Check if there are selected task types
            if (this.selectedTaskTypeValues && this.selectedTaskTypeValues.length > 0) {
                const selectedTaskTypeIds = this.selectedTaskTypeValues.map(taskType => taskType.value);
                this.orderOptions = this.allOrders.filter(item =>
                    selectedTaskTypeIds.includes(item.contract_task_type.task_type.id)
                );
            } else {
                this.orderOptions = this.allOrders;
            }
        },
        initGeometryOrders() {
            let geomVisible = this.getMapSettingsIn(['buttons', 'showContractGeometry'], false)
            let validatedOrders = []
            this.selectedGeometryOrderValues = []
            if (geomVisible) {
                let preSelectedGeomOrders = this.getMapSettingsIn(['buttons', 'selectedGeometryOrders'], []);
                if (preSelectedGeomOrders && preSelectedGeomOrders.length > 0) {
                    preSelectedGeomOrders.forEach(preSelectedOrder => {
                        this.orderOptions.forEach(order => {
                            if (order.id === preSelectedOrder.id) {
                                validatedOrders.push(order)
                                this.selectedGeometryOrderValues.push(order.id)
                            }
                        })
                    })
                }
            }
            this.selectGeometryOrders(validatedOrders)
            this.setShowContractGeometry(geomVisible)
        }
    }
}
</script>
<style lang="scss" scoped>
.closed {
    color: #9e9d9d;
    font-style: italic;
}
.multiselect-expand {
    height: 330px;
}

</style>
